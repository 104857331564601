import { Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";
import PropTypes from "prop-types";

const TextWithHereLink = ({ text, to, onClick }) => {
  if (to) {
    return (
      <Typography
        align="left"
        variant="subtitle1"
        sx={{
          textAlign: "center",
        }}
      >
        {text}
        <MuiLink component={RouterLink} to={to}>
          こちら
        </MuiLink>
      </Typography>
    );
  }

  return (
    <Typography
      align="left"
      variant="subtitle1"
      sx={{
        textAlign: "center",
      }}
    >
      {text}
      <MuiLink
        href="#"
        onClick={(event) => {
          event.preventDefault();
          onClick(event);
        }}
      >
        こちら
      </MuiLink>
    </Typography>
  );
};

TextWithHereLink.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

export default TextWithHereLink;