import { API } from "@aws-amplify/api";
import { PubSub } from "@aws-amplify/pubsub";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Amplify } from "aws-amplify";
import CssBaseline from "@mui/material/CssBaseline";
import { Authenticator } from "@aws-amplify/ui-react";
import awsconfig from "./aws-exports";
import "./App.css";

import awsExports from "./aws-exports";

import Dashboard from "./views/Dashboard";
import UsageSituation from "./views/UsageSituation/UsageSituation";
import { SnackbarProvider } from "notistack";
import WordRegistration from "./views/WordRegistration/WordRegistration";
import RegisterNewWord from "./views/WordRegistration/RegisterNewWord";
import EditWord from "./views/WordRegistration/EditWord";
import { ViewWithAuth } from "views/ViewWithAuth";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import Login from "./views/Login/Login";
import FederatedLogin from "./views/Login/FederatedLogin";
import VarificationCode from "./views/VarificationCode";
import PasswordReissue from "./views/PasswordReissue";
import CorporateProperWordList from "./views/WordRegistration/CorporateProperWordList";
import WithFeatureNotificationModal from "./components/WithFeatureNotificationModal";
import { TenantOptionsProvider } from "components/TenantOptionsProvider";
import FileUpload from "views/FileUpload";
import ProperNouns from "views/WordRegistration/ProperNouns";
import { TenantPlanProvider } from "components/TenantPlanProvider";
/**
 * aws-exportsの中身からAmplifyの環境名を返す関数
 * @returns {string} //Amplifyバックエンドの環境名
 */
const getBackendEnvName = () => {
  const stringArray = awsExports.aws_content_delivery_bucket.split("-");
  const envName = stringArray[stringArray.length - 1];
  return envName;
};

//現在のURLのドメイン名までを取得
const redirectUrlBase = `${window.location.origin}/`;

Amplify.configure({
  ...awsExports,
  oauth: {
    domain: `o-ninja-${getBackendEnvName()}-sso.auth.${awsExports.aws_cognito_region}.amazoncognito.com`,
    scope: ["openid", "email", "aws.cognito.signin.user.admin"],
    redirectSignIn: redirectUrlBase,
    redirectSignOut: `${redirectUrlBase}logout/`,
    responseType: "code", //authorization flow
  },
  Auth: {
    cookieStorage: {
      // (optional) - Cookie path
      path: "/",
      // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      sameSite: "strict",
      // (optional) - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true,
    },
  },
});

API.configure(awsconfig);
PubSub.configure(awsconfig);

const urlParams = new URLSearchParams(window.location.search);
const sessionId = urlParams.get("sessionid");
if (sessionId) {
  sessionStorage.setItem("sessionId", sessionId);
}

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <Authenticator.Provider>
            <TenantOptionsProvider>
              <TenantPlanProvider>
                <WithFeatureNotificationModal>
                  <BrowserRouter>
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <ViewWithAuth>
                            <Dashboard />
                          </ViewWithAuth>
                        }
                      />
                      <Route
                        path="/proper-nouns"
                        element={
                          <ViewWithAuth>
                            <ProperNouns />
                          </ViewWithAuth>
                        }
                      />
                      <Route
                        path="/word-registration"
                        element={
                          <ViewWithAuth>
                            <WordRegistration />
                          </ViewWithAuth>
                        }
                      />
                      <Route
                        path="/register-new-word"
                        element={
                          <ViewWithAuth>
                            <RegisterNewWord />
                          </ViewWithAuth>
                        }
                      />
                      <Route
                        path="/edit-word"
                        element={
                          <ViewWithAuth>
                            <EditWord />
                          </ViewWithAuth>
                        }
                      />
                      <Route
                        path="/corporate-words"
                        element={
                          <ViewWithAuth>
                            <CorporateProperWordList />
                          </ViewWithAuth>
                        }
                      />
                      <Route
                        path="/usage-situation"
                        element={
                          <ViewWithAuth>
                            <UsageSituation />
                          </ViewWithAuth>
                        }
                      />
                      <Route
                        path="/file-upload"
                        element={
                          <ViewWithAuth>
                            <FileUpload />
                          </ViewWithAuth>
                        }
                      />
                      <Route path="/login" element={<Login />} />
                      <Route
                        path="/federated-login"
                        element={<FederatedLogin />}
                      />
                      <Route
                        path="/varification-code"
                        element={<VarificationCode />}
                      />
                      <Route
                        path="/password-reissue"
                        element={<PasswordReissue />}
                      />
                      <Route path="/terms_of_service.html" />
                      <Route path="/privacy_policy.html" />
                      <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                  </BrowserRouter>
                </WithFeatureNotificationModal>
              </TenantPlanProvider>
            </TenantOptionsProvider>
          </Authenticator.Provider>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
