import React, { useState } from "react";
import { View } from "@aws-amplify/ui-react";
import { useSnackbar } from "notistack";
import { API } from "aws-amplify";
import {
    Typography,
    TextField,
    Button,
    Box,
    useTheme,
    CircularProgress,
} from "@mui/material";
import CopyrightText from "../../components/CopyrightText";
import TextWithHereLink from "./TextWithHereLink";
import "@aws-amplify/ui-react/styles.css";
import "../../App.css";
import { getIdpForDomain } from "../../graphql/queries.js";
import * as Yup from "yup"; // for email
import { Auth } from 'aws-amplify';

export default function FederatedLogin() {
    const emailSchema = Yup.string().email("有効なメールアドレスを入力してください").required("メールアドレスは必須です。");

    const { enqueueSnackbar } = useSnackbar();
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const handleChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        try {
            emailSchema.validateSync(value);
            setEmailError(false); // バリデーション成功時にエラーをリセット
        } catch (error) {
            setEmailError(true); // バリデーション失敗時にエラーをセット
        }
    };
    const theme = useTheme();

    const handleFederatedSignIn = async (e) => {
        e.preventDefault();
        setLoading(true);

        const domain = email.split("@")[1];

        try {
            const response = await API.graphql({
                query: getIdpForDomain,
                variables: { domain },
                authMode: "API_KEY",
            });
            const idpName = response.data.getIdpForDomain?.idpName;
            if (idpName) {
                await Auth.federatedSignIn({ provider: idpName });
            } else {

                enqueueSnackbar("お使いのメールアドレスに対応する外部認証サービスがありません。", { variant: "error" });
                setLoading(false);
            }
        } catch (error) {
            enqueueSnackbar("エラーが発生しました。", { variant: "error" });
            setLoading(false);
        }
    };

    return (
        <View className="auth-wrapper">
            <Box
                className="auth-content"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100vh",
                    maxWidth: "30rem",
                    margin: "0 auto",
                    padding: theme.spacing(3),
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        className="auth-content-header"
                        sx={{ mb: 4, textAlign: "center" }}
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/logo.svg`}
                            style={{ width: "14rem" }}
                            alt="Logo"
                            className="auth-logo"
                        />
                    </Box>
                    <Typography
                        component="h2"
                        variant="h6"
                        align="center"
                        gutterBottom
                        className="auth-title"
                    >
                        会社のメールアドレスを入力してください
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleFederatedSignIn}
                        noValidate
                        sx={{ mt: 1, width: "100%" }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="メールアドレス"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={handleChange}
                            error={emailError}
                            helperText={emailError ? "有効なメールアドレスを入力してください" : ""}
                            InputProps={{
                                className: "auth-input",
                                type: "email",  // HTML5 のメールタイプを指定
                            }}
                            InputLabelProps={{
                                className: "auth-label",
                            }}
                            disabled={loading}
                        />
                        <Button
                            type="submit"
                            style={{ width: "290px" }}
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            className="auth-button auth-button-primary"
                            disabled={loading}
                        >
                            {loading ? (
                                <CircularProgress size={24} />
                            ) : (
                                "外部サービスでログイン"
                            )}
                        </Button>
                        <Box sx={{ mt: theme.spacing(7), textAlign: "center" }}>
                            <TextWithHereLink
                                text="通常のログインに戻る"
                                to="/login"
                            />
                        </Box>

                    </Box>
                </Box>

                <Box className="auth-footer">
                    <CopyrightText sx={{ paddingBottom: theme.spacing(5) }} />
                </Box>
            </Box>
        </View>
    );
}
