import {
  Typography,
  Box,
  useMediaQuery,
  CircularProgress,
  Stack,
} from "@mui/material";
import useProperNounsSettings from "hooks/useProperNounsSettings";
import { useState, useEffect, useRef, useContext, useCallback } from "react";
import { ProcessingProgress } from "../components/ProcessingProgress";
import { chat } from "../chat";
import TextField from "@mui/material/TextField";
import { ButtonWithAnalytics } from "../components/ComponentWithAnalytics";
import { RecordButton } from "../components/RecordButton";
import OverwriteConfirmationDialog from "../components/OverwriteConfirmationDialog";
import ClearConfirmationDialog from "./Dashboard/components/ClearConfirmationDialog";
import DownloadOrShareButton from "./Dashboard/components/DownloadOrShareButton";
import SummaryExecuteButton from "./Dashboard/components/SummaryExecuteButton";
import { copyTextToClipboard } from "../helper/copyToClipboard";
import { getTranscriptDataBySessionId } from "../helper/getTranscriptDataBySessionId";
import { useSnackbar } from "notistack";
import { TenantCustomMessage } from "../components/TenantCustomMessage";
import CustomToggleButton from "../components/CustomToggleButton";
import {
  scrollMUIMainComponent,
  scrollTextareaToCaret,
} from "../helper/scrollHelper";
import ClearIcon from "@mui/icons-material/Clear";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useVoiceRecognition from "../helper/useVoiceRecognition";
import { isIphone } from "../helper/deviceHelper";
import PropTypes from "prop-types";
import SummaryModeSelect from "../components/SummaryModeSelect";
import { summaryModeOptions } from "../constants/summaryModeOptions";
import { wordFileOptions } from "../constants/uploadFileOptions";
import { useTheme } from "@mui/material/styles";

import AnalyticsWithTenant from "../helper/AnalyticsWithTenant";
import { CreateSummarizedTextCount } from "../CreateSummarizedTextCount";
import { useAuthenticator } from "@aws-amplify/ui-react";
import LanguageModeSelect from "../components/LanguageModeSelect";
import {
  languageModeOptions,
  downloadAndSharingLabels,
} from "../constants/languageModeOptions";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { TenantOptionsContext } from "../components/TenantOptionsProvider";

import MonthlyTenantProcessedTextCountRepository from "../repositories/MonthlyTenantProcessedTextCountRepository";
import SummarizationSettingRepository from "../repositories/SummarizationSettingRepository";
import { CreateVoiceInputTextCount } from "../CreateVoiceInputTextCount";
import { getTenantUserGroup } from "../helper/userInfoHelper";
import useUsageLimitExceeded from "../helper/useUsageLimitExceeded";
import { TenantPlanContext } from "../components/TenantPlanProvider";
import useUsageSituation from "hooks/useUsageSituation";
import UsageLimitWarnig from "../components/UsageLimitWarning";
import { SystemAudioNotification } from "../components/SystemAudioNotification";
import ReplacementLabelForm from "../components/ReplacementLabelForm";
import TranscriptsPerLabelDialog from "../components/TranscriptsPerLabelDialog";
import AutoSummarizationSettingSwitch from "../components/Switch/AutoSummarizationSettingSwitch";
import JSZip from "jszip";

const RECORD_END_KEYWORD_LIST = [
  "入力終わり",
  "入力終わり、",
  "入力終わり。",
  "入力おわり",
  "入力おわり、",
  "入力おわり。",
];

const primaryButtonSize = {
  width: "10rem",
};

const autoSummarizationSettingName = "autoSummarization";

const monthlyTenantProcessedTextCountRepository =
  new MonthlyTenantProcessedTextCountRepository();

export default function Dashboard(props) {
  const { handleMessageInputChange, handleMessageOutputChange } = props;
  const [isUploading, setIsUploading] = useState(false);
  const [dragging, setDragging] = useState(false);

  const { setInitialProperNounsSettings } = useProperNounsSettings();

  useEffect(() => {
    setInitialProperNounsSettings();
  }, []);

  let messageInput = props.messageInput || "";
  let messageOutput = props.messageOutput || "";

  const [confirmationDialogOpen, setClearConfirmationDialogOpen] =
    useState(false);
  const [overwriteConfirmationDialogOpen, setOverwriteConfirmationDialogOpen] =
    useState(false);
  const [droppedFile, setDroppedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(loading);
  const latestRequestCountRef = useRef(0);
  const [summaryMode, setSummaryMode] = useState(summaryModeOptions.normal);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { user } = useAuthenticator((context) => [context.user]);
  const [isRunning, setIsRunning] = useState(false);
  const currentIntervalIdRef = useRef(null);
  const isFirstRender = useRef(false);
  // 音声入力中、定期的にデータベースに処理文字数をカウントアップする処理のための中間カウンター。定期処理実行時リセットされる。
  const currentVoiceInputTextCountRef = useRef(0);
  // 音声入力した文字数カウンター。定期処理実行でリセットされない。
  const [totalVoiceInputTextCount, setTotalVoiceInputTextCount] = useState(0);
  const [voiceInputStartTime, setVoiceInputStartTime] = useState("");
  const userInfo = user.getSignInUserSession().getAccessToken().payload;
  const tenantId = getTenantUserGroup(userInfo);
  const { currentIsUsageLimitExceeded, checkUsageLimitExceeded } =
    useUsageLimitExceeded();
  const [latestSpeakerLabel, setLatestSpeakerLabel] = useState("");
  const [isSpeakerRecognition, setIsSpeakerRecognition] = useState(false);

  const { tenantOptions } = useContext(TenantOptionsContext);
  const { tenantPlan } = useContext(TenantPlanContext);

  const [inputLanguageMode, setInputLanguageMode] = useState(
    languageModeOptions.japanese,
  );
  const [outputLanguageMode, setOutputLanguageMode] = useState(
    languageModeOptions.japanese,
  );
  const detailsRef = useRef(null);
  const [isLanguageModeSelectDisable, setLanguageModeSelectDisable] =
    useState(false);
  const [isOpenSystemAudioNotification, setIsOpenSystemAudioNotification] =
    useState(false);
  const [isOpenSystemAudioFailure, setIsOpenSystemAudioFailure] =
    useState(false);
  const [isOpenSystemAudioCancel, setIsOpenSystemAudioCancel] = useState(false);

  const [isOpenTranscriptsPerLabel, setIsOpenTranscriptsPerLabel] =
    useState(false);
  const [label, setLabel] = useState("");

  const [enabledAutoSummarization, setEnabledAutoSummarization] =
    useState(true);

  const {
    recognitionResultInProcess,
    recognitionFinalizedResult,
    isRecording,
    isStartingRecord,
    startRecording,
    stopRecording,
    voiceInputUsageTime,
    setVoiceInputUsageTime,
    pausedEndedReason,
  } = useVoiceRecognition();

  const { refreshActiveUsage, usageLimitStatus } = useUsageSituation();

  const ref = useRef(null);
  const inputRef = useRef(null);

  const returnTop = () => {
    scrollMUIMainComponent(ref.current, 0, -66);
  };

  // 初期表示時に実行
  useEffect(() => {
    const handleBeforeUnloadEvent = (event) => {
      if (messageInput || messageOutput) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnloadEvent);
    return () =>
      window.removeEventListener("beforeunload", handleBeforeUnloadEvent);
  }, [messageInput, messageOutput]);

  //sessionIdを照会してAmivoiceから文字起こしデータを取得する
  useEffect(() => {
    getTranscriptDataBySessionId(
      enqueueSnackbar,
      handleMessageInputChange,
      tenantOptions,
      setInputLanguageMode,
      setOutputLanguageMode,
      stopIntervalVoiceCountRequest,
    );
  }, []);
  // recognitionResultInProcess変化時に実行
  useEffect(() => {
    // Scroll to the caret position when the voice input result is updated.
    const element = inputRef.current;
    scrollTextareaToCaret(element);
  }, [recognitionResultInProcess]);

  useEffect(() => {
    loadingRef.current = loading;
  }, [loading]);

  // recognitionFinalizedResult変化時に実行
  useEffect(() => {
    const getDisplayLabel = (defaultSpeakerLabel, inputLanguage) => {
      let speakerLabel = defaultSpeakerLabel.replace(/(\d+)/, (match) =>
        match.padStart(2, "0"),
      );
      if (inputLanguage === languageModeOptions.japanese) {
        speakerLabel = speakerLabel.replace(/speaker(\d+)/, "発言者$1");
      }
      return speakerLabel;
    };
    let currentSpeakerLabel = latestSpeakerLabel;
    let resultText = "";

    if (!recognitionFinalizedResult) {
      return;
    }

    // summaryMode が meetingSpeakerRecognition の場合recognitionFinalizedResult の各要素（話者とテキスト）を処理します。
    // ・話者が前回の話者と異なる場合、新しい話者のラベルとテキストを結果テキストに追加します。
    // ・話者が前回の話者と同じ場合、テキストだけを結果テキストに追加します。
    // summaryMode が meetingSpeakerRecognition 以外の場合、recognitionFinalizedResult.text を結果テキストとします。
    if (isSpeakerRecognition) {
      recognitionFinalizedResult.forEach(
        ({ text, speakerLabel: amiVoiceDefaultSpeakerLabel }) => {
          if (amiVoiceDefaultSpeakerLabel !== currentSpeakerLabel) {
            // 現在の話者と前回の話者が異なる場合
            if (currentSpeakerLabel !== "") {
              // 話者切り替わりのタイミングで改行する
              resultText += "\n";
            }
            // ラベルをテキストに付与する
            resultText += `${getDisplayLabel(
              amiVoiceDefaultSpeakerLabel,
              inputLanguageMode,
            )}:\n${text}`;
            currentSpeakerLabel = amiVoiceDefaultSpeakerLabel;
          } else {
            // 前回の話者と同じ場合
            if (inputLanguageMode === languageModeOptions.english) {
              // 入力言語が英語の場合で、前回と同じ話者の場合はスペースを入れる
              resultText += " ";
            }
            resultText += text;
          }
        },
      );
      setLatestSpeakerLabel(currentSpeakerLabel);
    } else {
      resultText = recognitionFinalizedResult.text;
    }

    // 結果テキストが音声入力終了キーワードで終わっている場合、キーワード以外のテキストをメッセージに追加し、録音を停止します。
    let endKeyword = "";
    if (
      RECORD_END_KEYWORD_LIST.some((keyword) => {
        endKeyword = keyword;
        return resultText.endsWith(keyword);
      })
    ) {
      // Add the text other than the voice input end keyword to the message.
      handleMessageInputChange(
        (prev) => prev + resultText.replace(endKeyword, ""),
      );
      startOrStopRecording({});
      return;
    }

    // recognitionFinalizedResult の全テキスト長を計算し、
    // それを現在の音声入力テキスト数と全音声入力テキスト数に追加します。
    let totalLength = 0;
    // 話者自動認識の場合は配列で返ってくるため、配列の要素数分の文字数をカウントする。
    if (isSpeakerRecognition) {
      totalLength = recognitionFinalizedResult.reduce(
        (sum, result) => sum + result.text.length,
        0,
      );
    } else {
      totalLength = recognitionFinalizedResult.text.length;
    }

    currentVoiceInputTextCountRef.current += totalLength;
    setTotalVoiceInputTextCount(totalVoiceInputTextCount + totalLength);
    handleMessageInputChange((prev) => prev + resultText);
  }, [recognitionFinalizedResult]);

  useEffect(() => {
    isFirstRender.current = true;
  }, []);
  // isRecording変化時に実行
  // Send message when recording is completed.
  useEffect(() => {
    if (isRecording || !enabledAutoSummarization) {
      return;
    }
    const send = async () => {
      await sendMessageAndWaitResponse();
    };
    // Skip if the first render.
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      send();
    }
  }, [isRecording]);

  // システム音声の設定が失敗したとき
  useEffect(() => {
    if (Object.keys(pausedEndedReason).length > 0 && pausedEndedReason) {
      //その他終了時は何もしない
      if (pausedEndedReason.code === 0) {
        return;
      }

      // システム音声のポップアップに使用するフラグを初期化
      setIsOpenSystemAudioCancel(false);
      setIsOpenSystemAudioFailure(false);

      if (pausedEndedReason.message.includes("Permission denied")) {
        // システムオーディオキャンセル時
        setIsOpenSystemAudioCancel(true);
        setIsOpenSystemAudioNotification(true);
      } else if (
        pausedEndedReason.message.includes(
          "System audio not found or no audio tracks",
        )
      ) {
        // システムオーディオ取得失敗時
        setIsOpenSystemAudioFailure(true);
        setIsOpenSystemAudioNotification(true);
      }
    }
  }, [pausedEndedReason]);

  useEffect(() => {
    (async () => {
      const settings = await SummarizationSettingRepository.loadSettings();
      if (Object.keys(settings) != 0) {
        setEnabledAutoSummarization(settings[autoSummarizationSettingName]);
      }
    })();
  }, []);

  const startOrStopRecording = async ({
    isRecordingWithSystemAudio = false,
  }) => {
    try {
      if (isRecording) {
        stopRecording();

        // システム音声を含める判定フラグのグローバル変数を削除
        if (window.isRecordingWithSystemAudio) {
          delete window.isRecordingWithSystemAudio;
        }

        stopIntervalVoiceCountRequest();
        return;
      } else {
        // システム音声を含める場合は、windowオブジェクトのグローバル変数にフラグをセット
        // グローバル変数を追加
        window.isRecordingWithSystemAudio = isRecordingWithSystemAudio;

        startIntervalVoiceCountRequest();
        setVoiceInputStartTime(new Date().toISOString());
        // switch depends on summary mode
        loadLanguageMode(summaryMode.value);
        const options = {
          useDiarizer: isSpeakerRecognition,
          language: inputLanguageMode,
        };
        if (Object.keys(options).length === 0) {
          throw Error("Summary mode is invalid.", {
            cause: { code: "invalidSummaryMode" },
          });
        }
        await startRecording(options);

        let recordName = "recordAudioFromMic";

        if (summaryMode.useSystemAudio) {
          recordName = "recordAudioFromSystemAudio";
        }

        AnalyticsWithTenant.record({
          name: recordName,
        });
      }
    } catch (e) {
      console.error(e);
      let message = "エラーが発生しました。";
      if (e.cause && e.cause.code === "invalidSummaryMode") {
        message = "まとめモードが不正です。";
      }
      enqueueSnackbar(message, { variant: "error" });
    }
  };
  const sendMessageAndWaitResponse = async () => {
    await sendMessage(++latestRequestCountRef.current, messageInput);
  };

  const sendMessage = async (currentRequestCount, summarizedTarget) => {
    handleMessageOutputChange("");
    setLoading(true);
    scrollMUIMainComponent(ref.current, 0, -66);
    if (summarizedTarget.trim() === "") {
      setLoading(false);
      return;
    }
    await checkUsageLimitExceeded();
    if (currentIsUsageLimitExceeded.current) {
      setLoading(false);
      return;
    }
    try {
      // switch depends on summary mode
      let output = "";
      const summaryStartTime = new Date().toISOString();
      // タグ発言者{number}:と改行を除いた文字数をカウントする
      let summarizedTextCount = messageInput.replace(
        /発言者\d+:|\n/g,
        "",
      ).length;
      if (summaryMode.value === "normal") {
        output = await sendMessageNormal(summarizedTarget);
      } else {
        const { summarizedText } = await chat(
          summarizedTarget,
          summaryMode.value,
          summaryMode.promptId,
          inputLanguageMode.value,
          outputLanguageMode.value,
        );
        output = summarizedText;
      }
      AnalyticsWithTenant.record({
        name: "sendMessage",
        attributes: {
          mode: summaryMode.value,
          speakerRecognition: isSpeakerRecognition,
          inputLang: inputLanguageMode.value,
          outputLang: outputLanguageMode.value,
        },
      });
      if (
        loadingRef.current &&
        latestRequestCountRef.current === currentRequestCount
      ) {
        handleMessageOutputChange(output);
        if (!isIphone()) {
          copyOutput(output);
        }
      }
      await saveSummarizedTextCount(summaryStartTime, summarizedTarget);
      await saveVoiceInputTextCount(voiceInputStartTime);
      await updateMonthlyProcessedTextCount(
        summarizedTextCount -
          totalVoiceInputTextCount +
          currentVoiceInputTextCountRef.current,
      );
      // Reset the voice input text count.
      currentVoiceInputTextCountRef.current = 0;
      setTotalVoiceInputTextCount(0);
    } catch (e) {
      console.error(e);
      let snackbarMessage = "";
      switch (e?.cause?.code) {
        case "Token refresh error":
          snackbarMessage =
            "エラーが発生しました。もう一度お試しいただくか、ログインし直してから再度実行してください。";
          break;
        case "exceeding_number_of_characters":
        case "context_length_exceeded":
        case "input_message_exceed_max_length":
          snackbarMessage =
            "一度に要約できる文字数を超過しました。入力内容を減らして、もう一度お試しください。";
          break;
        case "invalid_parameter_value":
          snackbarMessage =
            "入力内容が不正です。ログインし直してから再度実行してください。";
          break;
        case "content_filter":
          snackbarMessage =
            "生成した文章に不適切な内容が含まれていました。入力内容をご確認の上、もう一度お試しください。";
          break;
        case "exceeded_available_rate":
          snackbarMessage =
            "アクセスが集中しています。時間をあけて、もう一度お試しください。";
          break;
        case "no_tenant_exists":
          snackbarMessage = "エラーが発生しました。テナントが見つかりません。";
          break;
        case "no_resource_exists":
          snackbarMessage =
            "エラーが発生しました。有効なリソースが見つかりません。";
          break;
        default:
          snackbarMessage = e.message;
      }

      enqueueSnackbar(snackbarMessage, { variant: "error" });
    } finally {
      if (
        loadingRef.current &&
        latestRequestCountRef.current === currentRequestCount
      ) {
        setLoading(false);
      }
      setVoiceInputUsageTime(0);
      // 2回目以降の音声ラベルと同様の動作をさせるため、ここではダミーの値をセットする
      setLatestSpeakerLabel("dummy");
    }
  };

  const sendMessageNormal = async (summarizedTarget) => {
    let { summarizedText, revisedText } = await chat(
      summarizedTarget,
      summaryMode.value,
      summaryMode.promptId,
      inputLanguageMode.value,
      outputLanguageMode.value,
    );

    summarizedText = convertLineFeedCode(summarizedText);
    revisedText = convertLineFeedCode(revisedText);

    return outputLanguageMode.value === "en"
      ? `Points\n${summarizedText}\n\nProofread Texts\n${revisedText}`
      : `ポイント\n${summarizedText}\n\n校正文\n${revisedText}`;
  };

  const saveSummarizedTextCount = async (startTime, summarizedTarget) => {
    await CreateSummarizedTextCount(
      startTime,
      summarizedTarget.length,
      summaryMode.value,
      user.username,
      inputLanguageMode.value,
      outputLanguageMode.value,
      tenantId,
    );
  };

  const saveVoiceInputTextCount = async (startTime) => {
    if (!tenantPlan?.isProcessedTextBasedPlan) {
      return;
    }
    if (startTime) {
      await CreateVoiceInputTextCount(
        startTime,
        totalVoiceInputTextCount,
        Math.floor(voiceInputUsageTime / 1000) % 60,
        user.username,
        summaryMode.value,
        tenantId,
      );
    }
  };

  const updateMonthlyProcessedTextCount = async (textCount) => {
    if (!tenantPlan?.isProcessedTextBasedPlan) {
      return;
    }
    try {
      await monthlyTenantProcessedTextCountRepository.incrementMonthlyTenantProcessedTextCount(
        textCount,
        tenantId,
      );
      await refreshActiveUsage();
    } catch (e) {
      console.error(e);
    }
  };

  const startIntervalVoiceCountRequest = () => {
    if (!tenantPlan?.isProcessedTextBasedPlan) {
      return;
    }
    if (!isRunning) {
      const id = setInterval(() => {
        updateMonthlyProcessedTextCount(currentVoiceInputTextCountRef.current);
        checkUsageLimitExceeded();
        // Reset the voice input text count.
        currentVoiceInputTextCountRef.current = 0;
      }, 60000);

      currentIntervalIdRef.current = id;
      setIsRunning(true);
    }
  };

  const stopIntervalVoiceCountRequest = () => {
    if (currentIntervalIdRef.current) {
      clearInterval(currentIntervalIdRef.current);
      setIsRunning(false);
    }
  };

  /**
   * Convert line feed code \\n to \n.
   * @param {string} text
   * @returns {string}
   */
  const convertLineFeedCode = (text) => {
    return text.replaceAll("\\n", "\n");
  };

  /**
   * Copy output message to clipboad.
   * Pass the text as an argument when messageOutput is not set immediately after setMessageOutput.
   */
  const copyOutput = async (text) => {
    await copyTextToClipboard(text ?? messageOutput);
    AnalyticsWithTenant.record({
      name: "copyOutput",
    });
    enqueueSnackbar("出力内容をコピーしました。", { variant: "success" });
  };

  /**
   * Copy output message to clipboad.
   */
  const copyInput = async () => {
    await copyTextToClipboard(messageInput);
    AnalyticsWithTenant.record({
      name: "copyInput",
    });
    enqueueSnackbar("入力内容をコピーしました。", { variant: "success" });
  };

  const getContentBySummaryMode = () => {
    let content = {};
    let inputLabel = "";
    let outputLabel = "";
    const outputLanguage =
      outputLanguageMode.value || languageModeOptions.Japanese;

    switch (summaryMode.value) {
      case summaryModeOptions.normal.value:
        inputLabel = downloadAndSharingLabels[outputLanguage].normal.input;
        outputLabel = downloadAndSharingLabels[outputLanguage].normal.output;
        break;
      case summaryModeOptions.meeting.value:
      case summaryModeOptions.detailedMeeting.value:
      case summaryModeOptions.meetingWithSystemAudio.value:
        // 入力言語のラベルは出力言語のラベルに合わせる
        inputLabel = downloadAndSharingLabels[outputLanguage].meeting.input;
        outputLabel = downloadAndSharingLabels[outputLanguage].meeting.output;
        break;
      default:
        inputLabel =
          downloadAndSharingLabels[outputLanguage].customPrompt.input;
        outputLabel =
          downloadAndSharingLabels[outputLanguage].customPrompt.output;
        break;
    }

    content = {
      text: `[${outputLabel}]\n${messageOutput}\n\n[${inputLabel}]\n${messageInput}`,
    };
    return content;
  };

  const handleClickCopyOutput = async () => {
    await copyOutput();
  };

  const handleClickClear = () => {
    setClearConfirmationDialogOpen(true);
  };

  const handleSummaryModeChange = (event) => {
    setSummaryMode(event);

    const isMeetingMode = [
      summaryModeOptions.normal.value,
      summaryModeOptions.meeting.value,
      summaryModeOptions.meetingWithSystemAudio.value,
      summaryModeOptions.detailedMeeting.value,
    ].includes(event.value);
    if (!isMeetingMode) {
      setLanguageModeSelectDisable(true);
    } else {
      loadLanguageMode(event.value);
      setLanguageModeSelectDisable(false);
    }
    loadLanguageMode(event.value);
  };

  const loadLanguageMode = (summaryMode) => {
    if (!tenantOptions?.multi_language_enabled) {
      return;
    }
    const inputKey = `inputLanguageMode-${summaryMode}`;
    const inputLang = localStorage.getItem(inputKey);
    const matchedInputOption = Object.values(languageModeOptions).find(
      (option) => option.value === inputLang,
    );
    setInputLanguageMode(matchedInputOption || languageModeOptions.japanese);

    const outputKey = `outputLanguageMode-${summaryMode}`;
    const outputLang = localStorage.getItem(outputKey);
    const matchedOutputOption = Object.values(languageModeOptions).find(
      (option) => option.value === outputLang,
    );
    setOutputLanguageMode(matchedOutputOption || languageModeOptions.japanese);
  };

  const handleInputLanguageModeChange = (event) => {
    setInputLanguageMode(event);
    const key = `inputLanguageMode-${summaryMode.value}`;
    localStorage.setItem(key, event.value);
    AnalyticsWithTenant.record({
      name: "changeInputLanguageMode",
      attributes: {
        lang: event.value,
      },
    });
  };

  const handleOutputLangaueModeChange = (event) => {
    setOutputLanguageMode(event);
    const key = `outputLanguageMode-${summaryMode.value}`;
    localStorage.setItem(key, event.value);
    AnalyticsWithTenant.record({
      name: "changeOutputLanguageMode",
      attributes: {
        lang: event.value,
      },
    });
  };

  const handleClickRecordButton = () => {
    if (summaryMode.useSystemAudio) {
      const hide = JSON.parse(
        localStorage.getItem("system-audio-notification-hide"),
      );

      // システム音声のポップアップに使用するフラグを初期化
      setIsOpenSystemAudioCancel(false);
      setIsOpenSystemAudioFailure(false);

      if (!isRecording && !hide) {
        // システム音声の設定通知が非表示にされていない場合、モーダルを表示する
        setIsOpenSystemAudioNotification(true);
      } else {
        startOrStopRecording({ isRecordingWithSystemAudio: true });
      }
    } else {
      if (window.isRecordingWithSystemAudio) {
        delete window.isRecordingWithSystemAudio;
      }
      startOrStopRecording({ isRecordingWithSystemAudio: false });
    }
  };

  const handleNotificationClose = () => {
    startOrStopRecording({ isRecordingWithSystemAudio: true });
  };

  const handleAutoSummaryChange = (event) => {
    SummarizationSettingRepository.updateSettings(
      autoSummarizationSettingName,
      event.target.checked,
    );
    setEnabledAutoSummarization(event.target.checked);
  };

  const validateFile = (file) => {
    if (!file) {
      enqueueSnackbar("ファイルをドロップまたは、選択してください。", {
        variant: "error",
      });
      return false;
    }
    if (!file.name.match(wordFileOptions.allowedExtentions)) {
      enqueueSnackbar("ファイル形式は.docxのみ対応しています。", {
        variant: "error",
      });
      return false;
    }
    if (file.size > wordFileOptions.maxFileSize) {
      enqueueSnackbar("ファイルサイズは10MB以下にしてください。", {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const handleReadTextFromFile = async (file) => {
    setDragging(false);
    setIsUploading(true);

    let text = "";
    try {
      if (file.name.match(/\.txt$/)) {
        text = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => resolve(event.target.result);
          reader.onerror = (error) => reject(error);
          reader.readAsText(file);
        });
      } else if (file.name.match(/\.docx$/)) {
        const arrayBuffer = await file.arrayBuffer();
        text = await extractTextFromDocx(arrayBuffer);
      }
      handleMessageInputChange(text);
      enqueueSnackbar("文字起こしが入力されました。", {
        variant: "success",
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar("文字起こしの読み取りに失敗しました。", {
        variant: "error",
      });
    } finally {
      setIsUploading(false);
    }
  };

  async function extractTextFromDocx(arrayBuffer) {
    const zip = await JSZip.loadAsync(arrayBuffer);
    const content = await zip.file("word/document.xml").async("text");
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(content, "text/xml");
    const paragraphs = xmlDoc.getElementsByTagName("w:p");
    let text = "";
    for (let p of paragraphs) {
      const textElements = p.getElementsByTagName("w:t");
      for (let t of textElements) {
        text += t.textContent;
      }
      text += "\n";
    }
    return text.trim();
  }

  const handleDrop = async (event) => {
    event.preventDefault();

    const file = event.dataTransfer.files[0];

    if (!validateFile(file)) {
      setDragging(false);
      setIsUploading(false);
      return;
    }

    if (messageInput) {
      setDroppedFile(file);
      setOverwriteConfirmationDialogOpen(true);
    } else {
      handleReadTextFromFile(file);
    }
  };

  const handleDragoverOrLeave = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
    setDragging(event.type === "dragover");
  }, []);

  return (
    <Stack
      id="main_input"
      spacing={3}
      maxWidth="640px"
      flexGrow={1}
      sx={{
        textAlign: "left",
        margin: (theme) =>
          useMediaQuery(theme.breakpoints.up("md"))
            ? theme.spacing(10)
            : theme.spacing(5),
      }}
    >
      <TenantCustomMessage id="custom_message" />
      {tenantPlan?.isProcessedTextBasedPlan && (
        <UsageLimitWarnig usageLimitStatus={usageLimitStatus} />
      )}
      <Stack
        direction="row"
        spacing={2}
        alignItems="flex-end"
        sx={{ pb: theme.spacing(1) }}
      >
        <SummaryModeSelect
          option={summaryMode}
          onChange={(event) => {
            handleSummaryModeChange(event);
            if (detailsRef.current) {
              detailsRef.current.open = false;
            }
          }}
          disabled={isRecording}
        />
        <CustomToggleButton
          toggleValue={"speakerRecognitionToggle"}
          isSelected={isSpeakerRecognition}
          handleOnChange={() => {
            setIsSpeakerRecognition(!isSpeakerRecognition);
          }}
          isDisabled={isRecording || isStartingRecord}
          pcLabel="話者自動認識"
          spLabel="話者認識"
        />
      </Stack>
      {tenantOptions?.multi_language_enabled && (
        <details
          id="inputOutputLangModeSelect"
          ref={detailsRef}
          onClick={(e) => {
            if (!isRecording && !isLanguageModeSelectDisable) {
              e.preventDefault();
            }
          }}
          style={{
            color:
              isRecording || isLanguageModeSelectDisable ? "#BFBFBF" : "black",
          }}
        >
          <summary
            id="languageModeSelect"
            style={{ textAlign: "left" }}
            onClick={() => {
              if (detailsRef.current) {
                detailsRef.current.open = !detailsRef.current.open;
              }
            }}
          >
            <Typography variant="subtitle1" component="span">
              入出力言語設定
            </Typography>
          </summary>
          <Box sx={{ mt: theme.spacing(3) }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <LanguageModeSelect
              option={inputLanguageMode}
              onChange={handleInputLanguageModeChange}
              disabled={isRecording}
              flowType={"Input"}
            />
            <ArrowForwardIcon />
            <LanguageModeSelect
              option={outputLanguageMode}
              onChange={handleOutputLangaueModeChange}
              disabled={isRecording}
              flowType={"Output"}
            />
          </Box>
        </details>
      )}
      <details
        id="replacementLabelForm"
        style={{
          color: isRecording ? "#BFBFBF" : "black",
        }}
      >
        <summary>
          <Typography variant="subtitle1" component="span">
            発言者ラベル変更
          </Typography>
        </summary>
        <Box sx={{ mt: theme.spacing(3) }}></Box>
        <ReplacementLabelForm
          messageInput={messageInput}
          handleMessageInputChange={handleMessageInputChange}
          disabled={isRecording}
          setOpen={setIsOpenTranscriptsPerLabel}
          setLabel={setLabel}
        />
      </details>
      {/* 報告内容の入力コンポーネント */}
      <Box>
        <Stack>
          <Typography
            variant="subtitle1"
            sx={{
              margin: "auto 0",
              mr: "1rem",
              mb: theme.spacing(1),
              textAlign: "left",
            }}
          >
            報告内容の入力
          </Typography>

          {isRecording && (
            <Typography
              variant="subtitle2"
              sx={{ margin: "auto 0", textAlign: "left" }}
            >
              {`音声入力中..."${RECORD_END_KEYWORD_LIST[0]}
                    "と言うと終了します。`}
            </Typography>
          )}
        </Stack>
        <Stack position="relative">
          <TextField
            id="input"
            inputRef={inputRef}
            multiline
            fullWidth
            rows={isRecording ? 13 : 12}
            value={messageInput + recognitionResultInProcess}
            placeholder={
              summaryMode.inputFormPlaceholder &&
              summaryMode.inputFormPlaceholder
            }
            onChange={(event) => {
              // Disable editing the text area while voice input is in progress.
              if (recognitionResultInProcess !== "") {
                return;
              }
              handleMessageInputChange(event.target.value);
            }}
            onDragOver={handleDragoverOrLeave}
            onDragLeave={handleDragoverOrLeave}
            onDrop={handleDrop}
            sx={{
              mb: isRecording ? "0.5rem" : "0",
              backgroundColor: dragging || isUploading ? "#eee" : "#fff",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
            }}
          >
            {isUploading && (
              <>
                <CircularProgress />
                <div>
                  ファイルをアップロード中です。
                  <br />
                  しばらくお待ちください...
                </div>
              </>
            )}
          </Box>
        </Stack>
        {!isRecording && (
          <Box
            sx={{ display: "flex", gap: "0.4rem", justifyContent: "flex-end" }}
          >
            <ButtonWithAnalytics
              eventName="clickCopyInput"
              id="inputCopy"
              variant="text"
              disabled={!messageInput || loading}
              onClickFunc={copyInput}
              startIcon={
                <ContentCopyIcon sx={{ width: "14px", margin: "-0.2rem" }} />
              }
              size="small"
            >
              コピー
            </ButtonWithAnalytics>
            <ButtonWithAnalytics
              eventName="clickClearInputText"
              id="clearInputText"
              variant="text"
              disabled={!messageInput || loading}
              onClickFunc={handleClickClear}
              startIcon={
                <ClearIcon sx={{ width: "14px", margin: "-0.2rem" }} />
              }
              size="small"
            >
              クリア
            </ButtonWithAnalytics>
          </Box>
        )}
        <Stack
          direction="row"
          spacing={3}
          alignItems="center"
          justifyContent="center"
        >
          <SummaryExecuteButton
            messageInput={messageInput}
            sendMessageAndWaitResponse={sendMessageAndWaitResponse}
            setLoading={setLoading}
            loading={loading}
            primaryButtonSize={primaryButtonSize}
          />
          <RecordButton
            sx={{
              margin: "0.4rem 0",
              ...primaryButtonSize,
            }}
            isRecording={isRecording && !currentIsUsageLimitExceeded.current}
            messageInput={messageInput}
            isStartingRecord={
              isStartingRecord && !currentIsUsageLimitExceeded.current
            }
            handleClickRecordButton={handleClickRecordButton}
          />
          <SystemAudioNotification
            isOpen={isOpenSystemAudioNotification}
            setIsOpen={setIsOpenSystemAudioNotification}
            onClose={handleNotificationClose}
            isSettingFailure={isOpenSystemAudioFailure}
            isSettingCancel={isOpenSystemAudioCancel}
          />
        </Stack>
        <Stack
          direction="row"
          spacing={3}
          alignItems="center"
          justifyContent="center"
          sx={{ pt: "0.75rem" }}
        >
          <AutoSummarizationSettingSwitch
            id="checkbox-enable-auto-summarization"
            onChange={handleAutoSummaryChange}
            checked={enabledAutoSummarization}
            label="音声入力後に自動でまとめる"
          />
        </Stack>
      </Box>
      <Stack ref={ref} position="relative">
        <Typography
          variant="subtitle1"
          sx={{ textAlign: "left", mb: theme.spacing(1) }}
        >
          報告内容
        </Typography>
        <TextField
          id="outlined-multiline-static"
          multiline
          fullWidth
          rows={15}
          onChange={(event) => {
            handleMessageOutputChange(event.target.value);
          }}
          value={messageOutput}
          placeholder={
            summaryMode.outputFormPlaceholder &&
            summaryMode.outputFormPlaceholder
          }
        />
        <Box
          sx={{
            position: "absolute",
            top: "35%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          {loading && (
            <>
              <ProcessingProgress />
              <img
                src={`${process.env.PUBLIC_URL}/ninja_face_animation.gif`}
                style={{ width: "7.5rem", paddingTop: theme.spacing(3) }}
                draggable="false"
              />
            </>
          )}
        </Box>
        <Stack alignItems="flex-end">
          <ButtonWithAnalytics
            eventName="clickCopyOutput"
            id="outputCopy"
            variant="text"
            disabled={!messageOutput || loading}
            onClickFunc={handleClickCopyOutput}
            startIcon={
              <ContentCopyIcon sx={{ width: "14px", margin: "-0.2rem" }} />
            }
            size="small"
          >
            コピー
          </ButtonWithAnalytics>
        </Stack>
        <DownloadOrShareButton
          getContentBySummaryMode={getContentBySummaryMode}
          enqueueSnackbar={enqueueSnackbar}
          messageOutput={messageOutput}
          loading={loading}
        />
        <ButtonWithAnalytics
          eventName="clickReturnToTop"
          variant="text"
          onClickFunc={returnTop}
          size="small"
          sx={{ mt: "0" }}
        >
          入力に戻る
        </ButtonWithAnalytics>
        <Typography variant="subtitle2">
          AIによる文章生成のため不適切な表現をする場合や、正しい情報が表示されない場合があります。
        </Typography>
      </Stack>
      <TranscriptsPerLabelDialog
        label={label}
        message={messageInput}
        open={isOpenTranscriptsPerLabel}
        setOpen={setIsOpenTranscriptsPerLabel}
      />
      <ClearConfirmationDialog
        enqueueSnackbar={enqueueSnackbar}
        handleMessageInputChange={handleMessageInputChange}
        setLatestSpeakerLabel={setLatestSpeakerLabel}
        confirmationDialogOpen={confirmationDialogOpen}
        setClearConfirmationDialogOpen={setClearConfirmationDialogOpen}
      />
      <OverwriteConfirmationDialog
        openStatus={overwriteConfirmationDialogOpen}
        setOpenStatus={setOverwriteConfirmationDialogOpen}
        handleReadTextFromFile={handleReadTextFromFile}
        setDragging={setDragging}
        file={droppedFile}
      />
    </Stack>
  );
}

Dashboard.propTypes = {
  messageInput: PropTypes.string,
  messageOutput: PropTypes.string,
  handleMessageInputChange: PropTypes.func,
  handleMessageOutputChange: PropTypes.func,
};
